export const buildUrl = (source_id, filters) => {
  const include = {
    permit_type: {
      only: ['name']
    },
    application: {
      include: {
        vehicle: {
          include: {
            contractor: { only: ['id', 'name'] },
            subproject: {},
            model: {},
            vehicle_type: {}
          }
        },
        status: {}
      }
    }
  }
  const order = [{ date_to: 'desc' }]

  const config = JSON.stringify({
    ...filters,
    order,
    include
  })
  return `objectInfo/${source_id}?config=${config}`
}

export const buildExportUri = (source_id, filters) => {
  const except = [
    'id',
    'created_by',
    'application_id',
    'updated_by',
    'permit_type_id',
    'disabled'
  ]

  const include = {
    application: {
      only: ['app_id'],
      include: {
        status: {
          only: ['name']
        }
      }
    },
    permit_type: { only: ['name'] },
    status: {
      only: ['name']
    },
    vehicle: {
      only: ['reg_number'],
      include: {
        model: {
          only: ['name']
        },
        vehicle_type: {
          only: ['name']
        },
        contractor: {
          only: ['name']
        },
        subproject: {
          only: ['name']
        }
      }
    }
  }

  const order = [{ date_to: 'desc' }]

  const config = JSON.stringify({
    ...filters,
    order,
    with_numeration: true,
    except,
    include
  })

  return `objectInfo/${source_id}?config=${config}`
}
