export const fields = {
  no: '№',
  subproject: 'Подпроект',
  number: '№ пропуска',
  date_from: 'Действует с',
  date_to: 'Действует по',
  reg_number: 'ГРЗ',
  vehicle_type: 'Вид ТС',
  model: 'Модель ТС',
  contractor: 'Подрядчик',
  permit_type: 'Тип пропуска',
  valid: 'Действителен'
}

export const columns = [
  'no',
  'reg_number',
  'subproject',
  'model',
  'vehicle_type',
  'contractor',
  'number',
  'date_from',
  'date_to',
  'permit_type',
  'valid'
]

export const exportConfig = {
  column_order: [
    'no',
    'application.vehicle.reg_number',
    'subproject.name',
    'application.vehicle.model.name',
    'application.vehicle.vehicle_type.name',
    'application.vehicle.contractor.name',
    'number',
    'date_from',
    'date_to',
    'permit_type.name',
    'is_valid'
  ],
  column_labels: [
    '№',
    'ГРЗ',
    'Подпроект',
    'Модель ТС',
    'Вид ТС',
    'Подрядчик',
    '№ пропуска',
    'Действует с',
    'Действует по',
    'Тип пропуска',
    'Действителен'
  ],
  with_numeration: true,
  order: [{ date_to: 'desc' }],
  only: ['number', 'date_from', 'date_to', 'is_valid'],
  include: {
    subproject: {
      only: ['name']
    },
    permit_type: {
      only: ['name']
    },
    application: {
      only: ['id'],
      except: ['id'],
      include: {
        vehicle: {
          only: ['reg_number'],
          include: {
            model: { only: ['name'] },
            vehicle_type: { only: ['name'] },
            contractor: { only: ['name'] }
          }
        }
      }
    }
  }
}
