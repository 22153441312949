import cloneDeep from 'lodash.clonedeep'

const model = {
  id: null,
  name: null,
  ids: {},
  children: []
}

const cardInfo = {
  layerId: null,
  name: '',
  properties: null
}

const editorState = {
  type: 'events',
  mode: 'create',
  id: null,
  layerId: null,
  geometry: null,
  properties: null,
  isDrawing: false,
  createdObjects: [],
  createdObject: null,
  featureToDelete: null,
  signsFixDistance: true,
  editByRoute: false,
  editByRouteGeom: null
}

const routerState = {
  geometry: null,
  activeRoutes: []
}

const state = {
  iconsLoading: false,
  eventsFilter: {
    category: null,
    interval: null
  },
  loadingLayers: [],
  flyToGeom: null,
  books: {
    signIcons: [],
    eventClasses: []
  },
  lists: {
    signs: [],
    events: []
  },
  racks: null,
  cardId: null,
  cardType: null,
  newCardId: null,
  editorState: cloneDeep(editorState),
  featureToEdit: null,
  needToUpdate: false,
  needToSave: false,
  routerState: cloneDeep(routerState),
  modalType: null,
  model,
  related: null,
  cardInfo,
  showSings: true,
  showEvents: true,
  relatedToProd: true
}

const mutations = {
  SET_ODD_FIELD(state, { field, value }) {
    state[field] = value
  },
  SET_ODD_MODEL(state, { id, name, ids, children }) {
    state.model.id = id
    state.model.name = name
    state.model.ids = ids
    state.model.children = children
  },
  ADD_ODD_LOADING_LAYER(state, id) {
    if (state.loadingLayers.indexOf(id) === -1) state.loadingLayers.push(id)
  },
  REMOVE_ODD_LOADING_LAYER(state, id) {
    state.loadingLayers.splice(state.loadingLayers.indexOf(id), 1)
  },
  SET_ODD_EDITOR_STATE(state, info) {
    Object.keys(info).forEach(key => {
      state.editorState[key] = info[key]
    })
  },
  SET_ODD_EDITOR_PROP(state, { field, value }) {
    state.editorState[field] = value
  },
  CLEAR_ODD_EDITOR_STATE(state) {
    Object.keys(editorState).forEach(key => {
      state.editorState[key] = cloneDeep(editorState[key])
    })
    state.editorState.editByRoute = false
  },
  SET_CREATED_OBJECT(state, object) {
    state.editorState.createdObject = object
  },
  SET_ODD_LIST(state, { type, list }) {
    state.lists[type] = list
  },
  SET_ODD_BOOK(state, { type, book }) {
    state.books[type] = book
  },
  SET_ODD_ROUTER_PROP(state, { field, value }) {
    state.routerState[field] = value
  },
  ADD_ODD_ROUTER_ACTIVE_ITEM(state, item) {
    state.routerState.activeRoutes.push(item)
  },
  REMOVE_ODD_ROUTER_ACTIVE_ITEM(state, item) {
    state.routerState.activeRoutes = state.routerState.activeRoutes.filter(
      r => r.id !== item.id
    )
  },
  CLEAR_ODD_ROUTER_ACTIVE_ITEMS(state) {
    state.routerState.activeRoutes.splice(0)
  },
  ODD_SET_EVENTS_FILTER(state, [type, filter]) {
    state.eventsFilter[type] = filter
  }
}

export default {
  state,
  mutations
}
