<template>
  <div class="active-cards">
    <el-tabs
      v-model="active"
      type="border-card"
      class="r-tabs colors content-padding"
      closable
      @tab-remove="removeTab"
    >
      <el-tab-pane
        v-for="(tab, i) in activeCards"
        :key="tab.id"
        :label="String(tab.tab_number) || `${i + 1}`"
        :name="`${tab.id}`"
      >
        <object-info
          :id="tab.id"
          :index="i"
          :sourcename="tab.source_id"
          :module="module"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ObjectInfo from './object-info.vue'
import debounce from 'lodash.debounce'

const modulesList = ['book', 'map']

export default {
  components: {
    ObjectInfo
  },
  props: {
    module: {
      type: String,
      required: true,
      validator: function(val) {
        return modulesList.indexOf(val) !== -1
      }
    }
  },
  computed: {
    activeCards() {
      let activeCards = []

      switch (this.module) {
        case 'book':
          activeCards = this.$store.state.commonData.activeCards.book
          break
        case 'map':
          activeCards = this.$store.state.commonData.activeCards.map
          break
      }

      return activeCards
    },
    active: {
      get() {
        return this.$store.state.commonData.activeCard[this.module]
      },
      set(value) {
        const source_id = this.activeCards?.find(f => f.id === value)?.source_id

        this.$store.commit('SET_ACTIVE_CARD', {
          card: value,
          module: this.module
        })

        if (source_id) {
          this.$store.commit('SET_MAP_ACTIVE_CARD_FEATURE', { source_id, id: value })
        }
      }
    },
    activeLayers() {
      let activeLayers

      switch (this.module) {
        case 'book':
          activeLayers = this.$store.state.book.activeBook.source_id
          break
        case 'map':
          activeLayers = this.$store.state.map.activeLayers
          break
      }

      return activeLayers
    }
  },
  watch: {
    activeCards: {
      handler: function() {
        this.setActiveTab()
      },
      deep: true
    },
    activeLayers() {
      this.checkOpenedCards()
    }
  },
  created() {
    this.setActiveTab(true)
  },
  methods: {
    setActiveTab(isCreated) {
      if (!this.activeCards.length) return
      if (this.active && isCreated) return
      this.active = `${this.activeCards[this.activeCards.length - 1].id}`
    },
    removeTab(id) {
      if (this.activeCards.length === 1) this.active = null
      this.$store.commit('REMOVE_ACTIVE_CARD', {
        module: this.module,
        id
      })
    },
    checkOpenedCards: debounce(function() {
      const initialCards = []

      switch (this.module) {
        case 'book':
          this.activeCards.forEach(e => initialCards.push(e))
          if (!initialCards.length) return
          initialCards.forEach(e => {
            if (this.activeLayers !== e.source_id) this.removeTab(e.id)
          })
          break
        case 'map':
          this.activeCards.forEach(e => initialCards.push(e))
          if (!initialCards.length) return
          initialCards.forEach(e => {
            if (this.activeLayers.indexOf(e.source_id) === -1) {
              this.removeTab(e.id)
            }
          })
          break
      }
    }, 500)
  }
}
</script>

<style lang="scss">
.active-cards {
  height: 100%;

  .r-tabs {
    height: 100%;
    .el-tabs__item {
      min-width: 70px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      .el-icon-close {
        position: absolute;
        top: 50% !important;
        transform: translateY(-50%) !important;
        right: -12px;
        transition: all 0.15s ease;
        &:before {
          display: block !important;
          transform: none !important;
        }
      }
      &.is-active {
        .el-icon-close {
          right: 8px;
        }
      }
    }

    .el-tabs__content {
      height: calc(100% - 39px);
    }

    .el-tab-pane {
      height: calc(100% - 16px);
      overflow: auto;
    }
  }
}
</style>
