<template>
  <div :class="['odd-sign-item', { noBg }]">
    <r-block
      no-padding
    >
      <r-text>Название</r-text>
      <el-input v-model="sign.name" />
    </r-block>
    <div class="odd-sign-item__row">
      <div class="odd-sign-item__label">
        <r-text>Иконка знака</r-text>
      </div>
      <div class="odd-sign-item__value">
        <el-select
          v-model="sign.sign_icon_id"
          class="r-select"
          size="default"
          popper-class="odd-sign-item__popper"
          filterable
          @change="handleSignChange"
        >
          <template slot="prefix">
            <icon-image
              v-if="!!sign.sign_icon_id"
              :resource_id="getIconResourceId(sign.sign_icon_id)"
              :size="24"
            />
            <r-icon
              v-else
              name="road-sign"
              :size="18"
            />
          </template>
          <el-option
            v-for="item in signIcons"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          >
            <icon-image
              :resource_id="item.resource_id"
              :size="24"
            />
            <r-text>
              {{ item.name }}
            </r-text>
          </el-option>
        </el-select>
      </div>
    </div>
    <r-block
      no-padding
      :gap="0.25"
    >
      <r-text>Время действия</r-text>
      <el-date-picker
        v-model="sign.end_time"
        format="dd.MM.yyyy HH:mm"
        type="datetime"
        class="r-date-picker"
        width="100%"
      />
    </r-block>
    <r-block
      no-padding
    >
      <r-text>Название линка</r-text>
      <r-text>{{ linkName }}</r-text>
    </r-block>
    <r-button
      v-if="$route.name !== 'odd-objects'"
      icon="trash"
      type="danger"
      title="Убрать знак"
      @click="$emit('delete-sign', sign)"
    />
    <files-comments
      v-if="$route.name === 'odd-objects'"
      :id="sign.id"
      :source_id="sourceId"
      class="odd-sign-item__row"
      wrap
      closed
      container
      column
    />
  </div>
</template>

<script>
import filesComments from '@/components/files-comments/files-comments'
import iconImage from '@/components/odd/map/components/icon-image'

export default {
  components: { filesComments, iconImage },
  props: {
    sign: {
      type: Object,
      required: true
    },
    noBg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    signIcons() {
      const unique = {}

      return this.$store.state.odd.books.signIcons?.filter(s => {
        if (!unique[s.name]) {
          unique[s.name] = true
          return true
        } else {
          return false
        }
      })
    },
    sourceId() {
      return this.$store.state.odd.model.ids.signs
    },
    linkName() {
      return this.sign?.rack?.link?.name || '—'
    }
  },
  methods: {
    getIconResourceId(id) {
      const icon = this.signIcons.find(icon => icon.id === id)

      return icon ? icon.resource_id : ''
    },
    handleSignChange(val) {
      const signName = this.signIcons?.find(s => s.id === val)?.name

      this.sign.name = `№ ${signName} ГОСТ`
    }
  }
}
</script>

<style lang="scss">
.odd-sign-item {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  background-color: var(--bg_containers);

  &.noBg {
    background-color: initial;
    padding: 0;
  }

  &__row {
    display: grid;
    grid-gap: 0.5rem;

    .el-select,
    .r-simple-card,
    .r-toggle-card {
      width: 100%;
    }
  }

  .el-date-editor.el-input {
    width: 100%;
  }

  &__value {
    .el-select {
      width: 100%;
      border-radius: 4px 4px 0 0;

      .el-input {
        &__inner {
          font-size: 12px;
          padding-left: 40px !important;
        }

        &__prefix {
          width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__popper {
    max-width: 380px;

    &.el-select-dropdown {
      border-radius: 0 0 4px 4px;
      margin-top: -2px !important;
      background-color: var(--field_droplist_bg) !important;
    }

    .el-select-dropdown {
      &__wrap {
        max-height: 40vh;
        margin-bottom: 0 !important;
      }

      &__list {
        grid-gap: 0.25rem;
        padding: 0.25rem;
        padding-right: 0.5rem;
        display: grid;
      }

      &__item {
        display: grid;
        padding: 0 0.25rem;
        grid-gap: 0.5rem;
        align-items: center;
        grid-auto-flow: column;
        border-radius: var(--border-radius);
      }
    }

    &-image {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
