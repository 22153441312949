export const fields = [
  {
    name: 'Изъят',
    type: 'boolean',
    model: 'withdrawal'
  },
  {
    name: 'Действует с',
    type: 'datetime',
    model: 'date_from'
  },
  {
    name: 'Действует по',
    type: 'datetime',
    model: 'date_to'
  },
  {
    name: '№ пропуска',
    type: 'number',
    locationType: 'nested',
    model: 'number'
  },
  {
    name: 'Тип пропуска',
    type: 'select',
    locationType: 'sub_nested',
    model: 'permit_type'
  },
  {
    name: 'Подпроект',
    type: 'select',
    select: 'subproject',
    model: 'subproject'
  },
  {
    name: 'Заявка на ТС',
    type: 'select',
    model: 'application',
    read_only: true,
    customField: 'vehicle',
    displayedField: 'reg_number'
  },
  {
    name: 'Действителен',
    type: 'boolean',
    model: 'is_valid'
  },
  {
    name: 'Вид ТС',
    locationType: 'sub_nested',
    target: 'vehicle_type',
    read_only: true,
    model: 'vehicle',
    parent: 'application'
  },
  {
    name: 'Модель ТС',
    locationType: 'sub_nested',
    target: 'model',
    read_only: true,
    model: 'vehicle',
    parent: 'application'
  },
  {
    name: 'Подрядчик',
    locationType: 'nested',
    target: 'contractor',
    read_only: true,
    model: 'vehicle',
    parent: 'application'
  }
]

export const titles = {
  create: 'Создать новый пропуск',
  view: 'Пропуск транспортного средства'
}

export const sources = {
  store: 'bdd',
  field: 'pass',
  updateField: 'updatePass'
}
