<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    :icon="icon"
    :title="title"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import mapboxgl from 'mapbox-gl'
import SimpleButton from '../buttons/simple-button'

import { jsonToGeojson } from '@/utils'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'kpp-agpz',
      title: 'КПП АГПЗ',
      icon: 'kpp-gate',
      url: 'objectInfo/c3c87a16-85d0-4160-8101-cedab2440e56?',
      config: {
        mapPoint: {
          type: 'circle',
          layout: {},
          paint: {
            'circle-radius': {
              base: 1.75,
              stops: [[12, 8], [18, 80]]
            },
            'circle-color': 'rgba(255, 134, 8, 0.7)',
            'circle-pitch-alignment': 'map',
            'circle-stroke-color': 'rgb(0,0,0)',
            'circle-stroke-width': 1
          }
        },
        mapSymbol: {
          type: 'symbol',
          layout: {
            'icon-image': 'kpp-gate',
            'icon-size': {
              base: 1.75,
              stops: [[12, 0.3], [18, 3]]
            }
          },
          paint: {
            'icon-color': 'rgb(20, 20, 20)'
          }
        }
      },
      layersIds: []
    }
  },
  methods: {
    async addHandler() {
      const { id, url } = this
      const config = {
        where: [{ field: 'geom', op: '!=', value: null }]
      }
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `${url}&config=${JSON.stringify(config)}`
        })
        if (!data) return
        const features = jsonToGeojson(Object.values(data))
        if (!features) return
        const source = this.mapgl.getSource(id)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: features
          })
          this.addLayers(id)
        } else {
          source.setData(features)
        }
        this.addLayerHandlers(id)
      } catch (error) {
        console.log(error)
      }
    },
    addLayerHandlers(layer) {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 15,
        anchor: 'left',
        className: 'info-layer__custom-popup'
      })

      this.mapgl.on('mousemove', layer, e => {
        this.addPopup(e, popup)
        this.mapgl.getCanvas().style.cursor = 'pointer'
      })

      this.mapgl.on('mouseleave', layer, _ => {
        this.mapgl.getCanvas().style.cursor = ''
        popup.remove()
      })
    },
    addPopup(e, popup) {
      const html = e.features?.map(f => {
        const data = f.properties
        const { name, no } = data

        const item = '<dl><dt>Слой: </dt><dd>' + this.title + '</dd><dt>Наименование: </dt><dd>' + (name || 'Не указано') + '</dd><dt>Номер: </dt><dd>' + (no || 'Не указано') + '</dd></dl>'

        return item
      })?.join('<hr size="1" noshade>')

      popup
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(this.mapgl)
    },
    addLayers(id) {
      const { mapPoint, mapSymbol } = this.config

      this.mapgl.addLayer({
        id: `${id}`,
        source: id,
        ...mapPoint
      })
      this.mapgl.addLayer({
        id: `${id}_s`,
        source: id,
        ...mapSymbol
      })
      this.layersIds = [id, `${id}_s`]
    }
  }
}
</script>

<style lang="scss">
.info-layer__custom-popup {
  z-index: 99;

  .mapboxgl-popup {
    &-tip {
      border: none !important;
    }

    &-content {
      background-color: var(--bg_surface);
      display: grid;
      align-items: center;
      justify-content: start;
      grid-gap: 0.25rem;
      grid-auto-flow: row;
      padding: 0;
      border-radius: var(--border-radius);
      border: 1px solid transparent;
      border-color: var(--dividers_low_contrast) !important;

      dl {
        padding: 0.25rem 0.5rem;
        margin: 0;
      }

      dt {
        margin: 0;
        color: var(--text_secondary);
        font-size: 12px;
      }

      dd {
        font-weight: bold;
        margin: 0;
        color: var(--text_primary);
      }
    }
  }
}
</style>
