<template>
  <div class="map-editor-mode">
    <r-text
      type="caption"
      style="margin-bottom: 4px;"
    >
      Выбор режима
    </r-text>
    <div class="map-editor-mode__buttons">
      <r-radio-group
        :button-list="modes"
        :active="mode"
        @change="toggleEditorMode"
      />
    </div>
    <div
      v-if="state.mode === 'create'"
      class="map-editor-mode__attributes"
    >
      <el-checkbox
        v-model="state.withAttributes"
        class="r-checkbox"
      >
        С указанием атрибутов
      </el-checkbox>
      <el-checkbox
        v-if="state.geom_type === 'multi_polygon'"
        v-model="state.multiPolygon"
        class="r-checkbox"
      >
        Создать как один объект
      </el-checkbox>
    </div>
  </div>
</template>

<script>
const modes = [
  { id: 'create', label: 'Создание' },
  { id: 'edit', label: 'Редактирование' }
]

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modes
    }
  },
  computed: {
    mode() {
      return this.state.mode
    }
  },
  methods: {
    toggleEditorMode(mode) {
      this.$emit('toggle-editor-mode', mode)
    }
  }
}
</script>

<style lang="scss">
.map-editor-mode {
  margin-bottom: 1rem;

  &__attributes {
    display: grid;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;

    .el-checkbox__label {
      color: var(--white_overlay);
      font-size: 12px;
    }
  }
}
</style>
