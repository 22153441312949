import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import filesComments from '@/components/files-comments/files-comments.vue'
import ObjectInfoActions from './object-info-actions.vue'
import { notifyFactory, parseObjectFields, errorParser } from '@/utils'

const activePanels = ['attributes', 'view-settings', 'actions', 'filesComments']

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    sourcename: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  components: {
    ObjectInfoActions,
    filesComments
  },
  data() {
    return {
      activeTab: 'basic',
      isLoadingTab: false,
      isLoading: false,
      tabsData: {},
      tableData: [],
      tableFields: [],
      fields: [],
      filteredFields: [],
      filteredFieldsValues: [],
      tabs: [],
      filteredTabs: [],
      filteredTabsValues: [],
      data: {},
      savedFields:
        this.$store.state.user_config.objectInfoFields[this.sourcename] || null,
      activePanels
    }
  },
  watch: {
    activeObjectsInfo: {
      handler: function(value) {
        const current = value[this.id]
        if (!current) return
        if (!current.updated) return
        current.updated = false
      },
      deep: true,
      immediate: true
    },
    '$store.state.commonData.update'(val) {
      if (val === this.sourcename) {
        this.init()

        setTimeout(() => {
          this.$store.commit('COMMON_DATA_SET', ['update', null])
        }, 128)
      }
    }
  },
  computed: {
    initialObjectInfo() {
      const initialObjectInfo = this.activeObjectsInfo

      if (initialObjectInfo[this.id]) {
        return initialObjectInfo[this.id].initialObjectInfo || []
      }

      return []
    },
    isEdited() {
      return this.fields.some(item =>
        this.isEditedField(item.value, item.title)
      )
    },
    activeObjectsInfo() {
      return this.$store.state.commonData.activeObjectsInfo[this.module] || []
    },
    activeCards() {
      return this.$store.state.commonData.activeCards[this.module] || []
    },
    sameSources() {
      return this.activeCards?.filter(f => f.source_id === this.sourcename)
    },
    permissions() {
      return this.$store.state.permissions.permissions
    },
    hasDeletePermission() {
      if (!this.permissions) return false
      const datasource = this.permissions.find(e => e.id === this.sourcename)
      if (!datasource) return false
      if (!datasource.cruds) return false
      const hasPermission = datasource.cruds[3]

      return hasPermission
    }
  },
  methods: {
    // set initial attributes
    setFields() {
      const activeObjectsInfo = this.$store.state.commonData.activeObjectsInfo[
        this.module
      ]
      if (activeObjectsInfo[this.id]) {
        this.fields =
          activeObjectsInfo[this.id].activeObjectInfo.sort(a => {
            return a.system_field ? -1 : 1
          }) || []
      }
      this.tabs = this.$clientStore
        .getDataFields(this.sourcename)
        .filter(field => field.source_id)
      if (this.savedFields) {
        this.filteredFieldsValues = this.savedFields.fields
        this.filteredFields = this.fields.filter(
          f => this.filteredFieldsValues.indexOf(f.title) > -1
        )
        this.filteredTabsValues = this.savedFields.tabs
        this.filteredTabs = this.tabs.filter(
          t => this.filteredTabsValues.indexOf(t.title) > -1
        )
      } else {
        this.filteredFieldsValues = []
        this.filteredFields = []
        this.fields.forEach(k => {
          if (!k.system_field && this.filteredFieldsValues.length < 9) {
            this.filteredFieldsValues.push(k.title)
            this.filteredFields.push(k)
          } else if (k.title === 'geom_area' || k.title === 'geom_length') {
            this.filteredFieldsValues.push(k.title)
            this.filteredFields.push(k)
          }
        })
        this.filteredTabs = this.tabs.slice()
        this.filteredTabsValues = this.filteredTabs.map(t => t.title)
      }
      this.isLoading = false
    },
    // filter attributes
    changeFields(values) {
      this.filteredFields = this.fields.filter(
        field => values.indexOf(field.title) > -1
      )
    },
    changeTabs(values) {
      this.filteredTabs = this.tabs.filter(
        field => values.indexOf(field.title) > -1
      )
    },
    isArrayType(type, value) {
      return (
        ['has_many', 'has_many_through'].indexOf(type) > -1 ||
        Array.isArray(value)
      )
    },
    isEditedField(field, title) {
      if (!this.initialObjectInfo) return
      const fields = this.$clientStore.getDataFields(this.sourcename)
      const { type } = fields.find(item => item.title === title)
      const initial = this.initialObjectInfo.find(item => item.title === title)
      if (!initial) return

      const currentValue =
        field === ''
          ? null
          : (type === 'decimal' || type === 'integer') && field !== null
            ? Number(field)
            : field
      const initialValue =
        initial.value === ''
          ? null
          : (type === 'decimal' || type === 'integer') && field !== null
            ? Number(initial.value)
            : initial.value
      return !isEqual(currentValue, initialValue)
    },

    // tab changing
    async changeTab(tab) {
      const { name } = tab
      if (name === 'basic') return
      if (!this.tabsData[name]) {
        this.isLoadingTab = true
        const field = this.tabs.find(item => item.title === name)
        const { source_id, type } = field
        const config = {
          only: ['id'],
          where: [{ field: 'id', op: '=', value: this.id }],
          include: { [name]: {} }
        }
        try {
          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url: `objectInfo/${this.sourcename}?config=${JSON.stringify(
              config
            )}`
          })
          if (!this.$clientStore.isFieldsInStore(source_id)) {
            const initialFields = await this.$store.dispatch('GET_REQUEST', {
              url: `objectFields/${source_id}`
            })
            const fields = parseObjectFields(initialFields, false)
            this.$store.commit('SET_OBJECT_FIELDS', { source_id, fields })
          }
          const fields = this.$clientStore
            .getDataFields(source_id)
            .filter(field => !field.source_id)
          const modifiedArray = data[this.id][name] ? [data[this.id][name]] : []
          this.tabsData[name] = {
            fields,
            data: type === 'belongs_to' ? modifiedArray : data[this.id][name]
          }
          this.tableData =
            type === 'belongs_to' ? modifiedArray : data[this.id][name]
          this.tableFields = fields
          this.isLoadingTab = false
        } catch (e) {
          errorParser.call(this, e)
          this.isLoadingTab = false
        }
      }
    },
    updateData(title, selected) {
      this.tabsData[title].data = [...selected]
      this.$forceUpdate()

      const field = this.tabs.find(item => item.title === title)
      if (!field) return
      const { type } = field

      if (type === 'belongs_to') {
        const data = {
          id: this.id,
          [title]: { id: selected[0].id }
        }
        console.log('saving belongs_to --- ', data)
      }
    },

    // common actions (saving, editing and other)
    cancelEditing() {
      const initial = this.initialObjectInfo

      for (let i = 0; i < initial.length; i++) {
        const initialField = initial[i]
        const currentField = this.fields.find(
          f => f.title === initialField.title
        )

        currentField.value = cloneDeep(initialField.value)
      }
    },
    async saveMultiple() {
      try {
        this.isLoading = true

        const activeObjectInfo = this.fields
        const modifiedFields = { id: Object.keys(this.activeObjectsInfo)[0] }
        const data = this.sameSources?.map(s => {
          for (let i = 0; i < activeObjectInfo.length; i++) {
            const item = activeObjectInfo[i].value
            const title = activeObjectInfo[i].title
            if (this.isEditedField(item, title)) {
              modifiedFields[title] = item
            } else if (title === 'id') {
              modifiedFields[title] = item
            }
          }

          return {
            ...modifiedFields,
            id: s.id
          }
        })

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.sourcename}`,
          data
        })

        const message = this.$t('save_success_message')
        this.$notify(
          notifyFactory('success', this.$t('save_success_title'), message)
        )
        this.$store.commit('COMMON_DATA_SET', ['update', this.sourcename])
      } catch (e) {
        const message = 'Сохранение не выполнено. Повторите попытку'
        const title = 'Ошибка'
        errorParser.call(this, e, message, title)
      } finally {
        this.isLoading = false
      }
    },
    saveEditing() {
      const activeObjectInfo = this.fields
      const data = []
      const modifiedFields = { id: Object.keys(this.activeObjectsInfo)[0] }
      for (let i = 0; i < activeObjectInfo.length; i++) {
        const item = activeObjectInfo[i].value
        const title = activeObjectInfo[i].title
        if (this.isEditedField(item, title)) {
          modifiedFields[title] = item
        } else if (title === 'id') {
          modifiedFields[title] = item
        }
      }
      data.push(modifiedFields)

      this.$store
        .dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.sourcename}`,
          data
        })
        .then(() => {
          const message = this.$t('save_success_message')
          this.$notify(
            notifyFactory('success', this.$t('save_success_title'), message)
          )
        })
        .catch(error => {
          const message = 'Сохранение не выполнено. Повторите попытку'
          const title = 'Ошибка'
          errorParser.call(this, error, message, title)
        })
    },
    deleteObject() {
      this.$store
        .dispatch('DELETE_REQUEST', {
          url: `objectInfo/${this.sourcename}?id=${this.id}`
        })
        .then(data => {
          const message = 'Удаление объекта выполнено успешно'
          this.$notify(notifyFactory('success', 'Удаление выполнено', message))
          this.$store.commit('CLOSE_OVERHEAD_WINDOW', this.index)
        })
        .catch(error => {
          const title = 'Ошибка'
          const message = 'Удаление не выполнено. Повторите попытку'
          errorParser.call(this, error, message, title)
        })
    },
    isNumber(e) {
      if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 46) {
        e.preventDefault()
      }
    },
    hideSection(header) {
      this.hidePanel[header]
        ? (this.hidePanel[header] = false)
        : (this.hidePanel[header] = true)
    },
    async setUserPermissions() {
      this.isLoading = true
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'permission_object_tree?'
        })
        if (!data.datasources) return
        this.$store.commit('SET_USER_PERMISSIONS', data.datasources)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    async init() {
      try {
        this.isLoading = true
        const config = {
          where: [
            {
              field: 'id',
              op: '=',
              type: 'AND',
              value: this.id
            }
          ]
        }
        if (this.$store.state.listeners.indexOf(this.id) === -1) {
          // checking if data exist
          this.$store.commit('ADD_LISTENER_ID', this.id)
          // addListenerToSocket.call(this, this.id) // add listener to socket
        }

        const initialFields = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${this.sourcename}`
        })
        const fields = parseObjectFields(initialFields, false)
        this.$store.commit('SET_OBJECT_FIELDS', {
          source_id: this.sourcename,
          fields
        })

        const url = `objectInfo/${this.sourcename}?config=${JSON.stringify(
          config
        )}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.$store.commit('ADD_ACTIVE_OBJECT_INFO', {
          sourcename: this.sourcename,
          data: data[this.id],
          id: this.id,
          module: this.module
        })
        this.setFields()
        if (!this.permissions || !this.permissions.length) {
          this.setUserPermissions()
        }
      } finally {
        this.isLoading = false
      }
    }
  },
  async created() {
    this.init()
  }
}
