<template>
  <div class="event-create-attributes">
    <div class="event-create-attributes__item">
      <r-text color-type="secondary">
        Название
      </r-text>
      <el-input v-model="object.name" />
    </div>
    <div class="event-create-attributes__item">
      <r-text color-type="secondary">
        Тип события
      </r-text>
      <el-select
        v-model="object.event_class_id"
        class="r-select"
        size="mini"
        @change="handleTypeChange"
      >
        <el-option
          v-for="item in eventClasses"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </div>
    <r-toggle-card
      v-if="geometryType === 'LineString'"
      class="event-create-attributes__item signs"
      title="Добавить знаки"
      :is-open="showSigns"
      @toggle="value => toggleCard('signs', value)"
    >
      <el-select
        v-model="object.sign_icon_id"
        class="r-select dark"
        filterable
        size="default"
        :popper-append-to-body="false"
        popper-class="event-create-attributes__popper"
      >
        <template slot="prefix">
          <icon-image
            v-if="!!object.sign_icon_id"
            :resource_id="getIconResourceId(object.sign_icon_id)"
            :size="24"
          />
          <r-icon
            v-else
            name="road-sign"
            type="additional"
            :size="18"
          />
        </template>
        <el-option
          v-for="item in signIcons"
          :key="item.name"
          :label="item.name"
          :value="item.id"
        >
          <icon-image
            :resource_id="item.resource_id"
            :size="24"
          />
          <r-text>
            {{ item.name }}
          </r-text>
        </el-option>
      </el-select>
    </r-toggle-card>
  </div>
</template>

<script>
import iconImage from '@/components/odd/map/components/icon-image'

export default {
  components: { iconImage },
  props: {
    object: {
      type: Object,
      required: true
    },
    geometryType: {
      type: String,
      default: 'Point'
    }
  },
  data() {
    return {
      date: this.$rDate.format(new Date(), 'DD.MM.YYYY'),
      showNotify: false,
      showSigns: false
    }
  },
  computed: {
    sourceId() {
      return this.$store.state.odd.model.ids.events
    },
    eventClasses() {
      return this.$store.state.odd.books.eventClasses
    },
    signIcons() {
      return this.$store.state.odd.books.signIcons
    }
  },
  mounted() {
    this.handleTypeChange(this.object.event_class_id)
  },
  methods: {
    handleTypeChange(val) {
      const signName = this.eventClasses?.find(s => s.id === val)?.name

      this.object.name = `${signName} ${this.date}`
    },
    toggleCard(type, value) {
      if (type === 'notify') {
        this.showNotify = value
      } else {
        this.showSigns = value
      }
    },
    getIconResourceId(id) {
      const icon = this.signIcons.find(icon => icon.id === id)

      return icon ? icon.resource_id : ''
    }
  }
}
</script>

<style lang="scss">
.event-create-attributes {
  display: grid;
  grid-gap: 1rem;

  &__item {
    display: grid;
    grid-gap: 0.5rem;

    &.signs {
      .el-select {
        width: 100%;
        border-radius: 4px 4px 0 0;

        .el-input {
          &__inner {
            font-size: 12px;
            padding-left: 40px !important;
          }

          &__prefix {
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  &__popper {
    max-width: 380px;

    &.el-select-dropdown {
      border-radius: 0 0 4px 4px;
      margin-top: -2px !important;
      background-color: var(--field_droplist_bg) !important;
    }

    .el-select-dropdown {
      &__wrap {
        max-height: 40vh;
        margin-bottom: 0 !important;
      }

      &__list {
        grid-gap: 0.25rem;
        padding: 0.25rem;
        padding-right: 0.5rem;
        display: grid;
      }

      &__item {
        display: grid;
        padding: 0 0.25rem;
        grid-gap: 0.5rem;
        align-items: center;
        grid-auto-flow: column;
        border-radius: var(--border-radius);
      }
    }

    &-image {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
