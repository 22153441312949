export const reportOptions = {
  bsmts_actual_mapping: {
    dateOption: 'none',
    services: [],
    tableFields: [
      {
        id: 'gps_time',
        title: 'Время последней полученной координаты',
        value: 'gps_time',
        type: 'date',
        dateFormat: 'DD.MM.YYYY - HH:mm:ss'
      },
      {
        id: 'created_at',
        title: 'Время записи последней полученной координаты',
        value: 'created_at',
        type: 'date',
        dateFormat: 'DD.MM.YYYY - HH:mm:ss'
      },
      {
        id: 'uid',
        title: 'ID BSMTS',
        value: 'uid'
      },
      {
        id: 'protocol',
        title: 'Протокол',
        value: 'protocol'
      },
      {
        id: 'reg_number',
        title: 'ГРЗ',
        value: 'reg_number'
      }
    ],
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ]
  },
  crossings_geozones: {
    statUpdateClass: 'CrossingGeozoneReport',
    services: [
      {
        id: 1,
        name: 'Транспортные средства',
        service: 'vehicles',
        serviceField: 'reg_number',
        value: null,
        urlField: 'vehicle_id',
        multiple: true
      },
      {
        id: 2,
        name: 'Геозоны',
        service: 'geozones',
        serviceField: 'name',
        value: null,
        urlField: 'geozone_id',
        multiple: true
      },
      {
        id: 3,
        name: 'Тип ТС',
        service: 'vehicleGroups',
        serviceField: 'name',
        value: null,
        urlField: 'type_id',
        optional: true,
        multiple: true
      },
      {
        id: 4,
        name: 'Подрядчики',
        service: 'mainContractor',
        serviceField: 'name',
        value: null,
        urlField: 'contractor_id',
        multiple: true
      },
      {
        id: 5,
        name: 'Субподрядчики',
        service: 'mainContractor',
        serviceField: 'name',
        value: null,
        urlField: 'subcontractor_id',
        multiple: true
      },
      {
        id: 6,
        name: 'Подподпроект',
        service: 'subsubprojects',
        serviceField: 'name',
        urlField: 'subproject_id',
        value: null,
        multiple: true
      }
    ],
    tableFields: [
      {
        id: 'reg_number',
        title: 'Т/С',
        value: 'reg_number'
      },
      {
        id: 'type_group',
        title: 'Тип ТС',
        value: 'type_group'
      },
      {
        id: 'project',
        title: 'Проект',
        value: 'project'
      },
      {
        id: 'subproject',
        title: 'Подпроект',
        value: 'subproject'
      },
      {
        id: 'contractor',
        title: 'Подрядчик',
        value: 'contractor'
      },
      {
        id: 'subcontractor',
        title: 'Субподрядчик',
        value: 'subcontractor'
      },
      {
        id: 'geozone_name',
        title: 'Геозона',
        value: 'geozone_name'
      },
      {
        id: 'enter_time',
        title: 'Время входа в геозону',
        value: 'enter_time',
        type: 'date',
        dateFormat: 'DD.MM.YYYY - HH:mm:ss'
      },
      {
        id: 'exit_time',
        title: 'Время выхода из геозоны',
        value: 'exit_time',
        type: 'date',
        dateFormat: 'DD.MM.YYYY - HH:mm:ss'
      },
      {
        id: 'duration',
        title: 'Длительность пребывания, чч:мм:сс',
        value: 'duration'
      },
      {
        id: 'distance',
        title: 'Пробег, км',
        value: 'distance'
      },
      {
        id: 'avg_speed',
        title: 'Средняя скорость в движении, км/ч',
        value: 'avg_speed'
      },
      {
        id: 'max_speed',
        title: 'Максимальная скорость в движении, км/ч',
        value: 'max_speed'
      }
    ],
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-map'
      }
    ]
  },
  movement_and_parkings: {
    statUpdateClass: 'MovementReport',
    services: [
      {
        id: 1,
        name: 'Транспортные средства',
        service: 'vehicles',
        serviceField: 'reg_number',
        value: null,
        urlField: 'vehicle_id',
        multiple: true
      }
    ],
    geomField: null,
    tableFields: [
      {
        id: 'reg_number',
        title: 'ГРЗ',
        value: 'reg_number'
      },
      {
        id: 'duration',
        title: 'Длительность',
        value: 'duration'
      },
      {
        id: 'start_time',
        title: 'Время начала',
        value: 'start_time',
        type: 'date',
        dateFormat: 'DD.MM.YYYY - HH:mm:ss'
      },
      {
        id: 'end_time',
        title: 'Время окончания',
        value: 'end_time',
        type: 'date',
        dateFormat: 'DD.MM.YYYY - HH:mm:ss'
      },
      {
        id: 'avg_speed',
        title: 'Средняя скорость, км/ч',
        value: 'avg_speed'
      },
      {
        id: 'max_speed',
        title: 'Максимальная скорость, км/ч',
        value: 'max_speed'
      },
      {
        id: 'distance',
        title: 'Путь, км',
        value: 'distance',
        type: 'number',
        toFixed: 3
      }
    ],
    hasActions: true,
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-map'
      }
    ]
  },
  movements_stat: {
    statUpdateClass: 'MovementReport',
    services: [
      {
        id: 1,
        name: 'Транспортные средства',
        service: 'vehicles',
        serviceField: 'reg_number',
        value: null,
        urlField: 'reg_number',
        key: 'reg_number',
        multiple: true
      }
    ],
    tableFields: [
      {
        id: 'reg_number',
        title: 'ГРЗ',
        value: 'reg_number'
      },
      {
        id: 'length',
        title: 'Длина пути, км',
        value: 'length'
      },
      {
        id: 'speed_avg',
        title: 'Средняя скорость перемещения, км/ч',
        value: 'speed_avg'
      },
      {
        id: 'duration',
        title: 'Длительность отчетного периода',
        value: 'duration'
      },
      {
        id: 'duration_movement',
        title: 'Общее время перемещения',
        value: 'duration_movement'
      },
      {
        id: 'duration_movement_percent',
        title: 'Общее время перемещения, %',
        value: 'duration_movement_percent'
      },
      {
        id: 'duration_parking',
        title: 'Общее время стоянок/остановок',
        value: 'duration_parking'
      },
      {
        id: 'duration_parking_percent',
        title: 'Общее время стоянок/остановок, %',
        value: 'duration_parking_percent'
      }
    ],
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ]
  },
  retranslation_status: {
    services: [
      {
        id: 1,
        name: 'Подрядчики',
        service: 'mainContractor',
        serviceField: 'name',
        value: null,
        urlField: 'main_contractor_id'
      }
    ],
    dateOption: 'date-and-time',
    tableFields: [
      {
        id: 'reg_number',
        title: 'ГРЗ',
        value: 'reg_number'
      },
      {
        id: 'name',
        title: 'Организация',
        value: 'name'
      },
      {
        id: 'status',
        title: 'Статус',
        value: 'status'
      },
      {
        id: 'dt_from',
        title: 'Время события',
        type: 'date',
        value: 'dt_from',
        dateFormat: 'DD.MM.YYYY HH:mm:ss'
      }
    ],
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ]
  },
  inspections: {
    services: [
      {
        id: 1,
        name: 'Подрядчик',
        service: 'mainContractor',
        multiple: true,
        serviceField: 'name',
        value: null,
        urlField: 'main_contractor_id',
        optional: true
      },
      {
        id: 2,
        name: 'Тип ТС',
        service: 'vehicleGroups',
        serviceField: 'name',
        value: null,
        urlField: 'type_id',
        optional: true
      }
    ],
    tableFields: [
      {
        id: 1,
        title: 'Подрядчик',
        value: 'Подрядчик'
      },
      {
        id: 2,
        title: 'Заявлено ТС',
        value: 'Заявлено ТС'
      },
      {
        id: 3,
        title: 'Осмотрено ТС',
        value: 'Осмотрено ТС'
      },
      {
        id: 4,
        title: 'Допущено ТС',
        value: 'Допущено ТС'
      },
      {
        id: 5,
        title: 'Доля, %',
        value: 'Доля'
      },
      {
        id: 6,
        title: 'Кол-во недостатков',
        value: 'Кол-во недостатков'
      }
    ],
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ]
  },
  supplies: {
    name: 'supplies',
    // dateOption: 'date',
    exportOnly: true,
    services: []
  },
  supplies_vehicle: {
    name: 'supplies_vehicle',
    // dateOption: 'date',
    exportOnly: true,
    services: []
  },
  supplies_cariages: {
    name: 'supplies_cariages',
    // dateOption: 'date',
    exportOnly: true,
    services: []
  },
  daily_dispatcher: {
    name: 'daily_dispatcher',
    dateOption: 'date',
    exportOnly: true,
    services: [
      {
        id: 'contractor',
        name: 'Поставщик',
        service: 'mainContractor',
        serviceField: 'name',
        urlField: 'contractors',
        value: null,
        multiple: true
      },
      {
        id: 'warehouse',
        name: 'Площадка',
        service: 'warehouses',
        serviceField: 'name',
        urlField: 'warehouses',
        value: null,
        multiple: true
      },
      {
        id: 'name',
        name: 'Наименование вид груза',
        hardUrl: 'objectInfo/telemetry.cargoes?config={"unique_values":"name"}',
        urlField: 'names',
        value: null,
        multiple: true
      },
      {
        id: 'nomenclature_group',
        name: 'Группа номенклатуры',
        hardUrl:
          'objectInfo/telemetry.cargoes?config={"unique_values":"nomenclature_group"}',
        urlField: 'nomenclature_groups',
        value: null,
        multiple: true
      },
      {
        id: 'project',
        name: 'Проект',
        hardUrl:
          'objectInfo/telemetry.cargoes?config={"unique_values":"project"}',
        serviceField: 'name',
        urlField: 'projects',
        value: null,
        multiple: true
      },
      {
        id: 'subprojects',
        name: 'Подпроект',
        service: 'subprojects',
        serviceField: 'name',
        urlField: 'subprojects',
        value: null,
        multiple: true
      },
      {
        id: 'subsubprojects',
        name: 'Подподпроект',
        service: 'subsubprojects',
        serviceField: 'name',
        urlField: 'subsubprojects',
        value: null,
        multiple: true
      }
    ]
  },
  forecast_arrival: {
    dateOption: 'none',
    tableFields: [
      {
        id: 'reg_number',
        title: 'ГРЗ',
        value: 'reg_number'
      },
      {
        id: 'carriage_no',
        title: 'Вагон',
        value: 'carriage_no'
      },
      {
        id: 'cargoes.sender.name',
        title: 'Организация',
        type: 'array',
        value: 'cargoes.sender.name',
        default: '-'
      },
      {
        id: 'cargoes.name',
        title: 'Груз',
        value: 'cargoes.name',
        type: 'array',
        default: '-'
      },
      {
        id: 'cargoes.invoice_no',
        title: 'Инвойс',
        value: 'cargoes.invoice_no',
        type: 'array',
        default: '-'
      },
      {
        id: 'cargoes.packing_list_no',
        title: 'Упаковочный лист',
        value: 'cargoes.packing_list_no',
        type: 'array',
        default: '-'
      },
      {
        id: 'cargoes.scn',
        title: 'SCN номер',
        value: 'cargoes.scn',
        type: 'array',
        default: '-'
      },
      {
        id: 'forecast_delivery',
        title: 'Прогноз дата прибытия',
        value: 'arrival_forecast',
        type: 'date',
        dateFormat: 'DD.MM.YYYY'
      }
    ],
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ]
  },
  passing_report_hourly_in: {
    dateOption: 'single-date',
    additionalProps: 'type=in',
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-chart'
      },
      {
        id: 3,
        value: 'table-map'
      },
      {
        id: 4,
        value: 'table-map-chart'
      }
    ]
  },
  passing_report_hourly_out: {
    dateOption: 'single-date',
    additionalProps: 'type=out',
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-chart'
      },
      {
        id: 3,
        value: 'table-map'
      },
      {
        id: 4,
        value: 'table-map-chart'
      }
    ]
  },
  passing_report_hourly_all: {
    dateOption: 'single-date',
    additionalProps: 'type=all',
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-chart'
      },
      {
        id: 3,
        value: 'table-map'
      },
      {
        id: 4,
        value: 'table-map-chart'
      }
    ]
  },
  passing_report_for_period_in: {
    additionalProps: 'type=in',
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-chart'
      },
      {
        id: 3,
        value: 'table-map'
      },
      {
        id: 4,
        value: 'table-map-chart'
      }
    ]
  },
  passing_report_for_period_out: {
    additionalProps: 'type=out',
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-chart'
      },
      {
        id: 3,
        value: 'table-map'
      },
      {
        id: 4,
        value: 'table-map-chart'
      }
    ]
  },
  passing_report_for_period_all: {
    additionalProps: 'type=all',
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-chart'
      },
      {
        id: 3,
        value: 'table-map'
      },
      {
        id: 4,
        value: 'table-map-chart'
      }
    ]
  },
  passing_report_for_period_by_type: {
    hasActions: true,
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-chart'
      },
      {
        id: 3,
        value: 'table-map'
      },
      {
        id: 4,
        value: 'table-map-chart'
      }
    ]
  },
  passing_report_load_for_period: {
    hasActions: true,
    workspace: [
      {
        id: 1,
        value: 'table'
      },
      {
        id: 2,
        value: 'table-map'
      }
    ]
  },
  cargo_report_vehicles_report: {
    dateOption: 'mm-yy-from',
    additionalProps: 'format=vehicles_report',
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ]
  },
  cargo_report_railway_report: {
    dateOption: 'mm-yy-from',
    additionalProps: 'format=carriages_report',
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ]
  },
  bdd_report: {
    dateOption: 'datetime',
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ],
    timeInterval: {
      id: 'time-interval',
      name: 'Время происшествия',
      type: 'time-interval',
      value1: new Date('2022-01-01T00:00:00'),
      value2: new Date('2022-01-01T23:59:59'),
      urlField: 'Время происшествия'
    },
    additionalFilters: [
      {
        id: 1,
        name: 'Есть погибшие',
        type: 'select',
        optionsList: [
          {
            id: 1,
            value: 'yes',
            label: 'Да'
          },
          {
            id: 2,
            value: 'no',
            label: 'Нет'
          }
        ],
        value: null,
        urlField: 'Погибло'
      },
      {
        id: 2,
        name: 'Есть раненые',
        type: 'select',
        optionsList: [
          {
            id: 1,
            value: 'yes',
            label: 'Да'
          },
          {
            id: 2,
            value: 'no',
            label: 'Нет'
          }
        ],
        value: null,
        urlField: 'Ранено'
      },
      {
        id: 3,
        name: 'Стаж',
        type: 'number-interval',
        value1: null,
        value2: null,
        urlField: 'Стаж'
      },
      {
        id: 4,
        name: 'Возраст',
        type: 'number-interval',
        value1: null,
        value2: null,
        urlField: 'Возраст'
      }
    ],
    services: [
      {
        id: 1,
        name: 'Категория происшествия',
        service: 'accident_categories',
        serviceField: 'name',
        value: null,
        urlField: 'Категория происшествия',
        multiple: true
      },
      {
        id: 2,
        name: 'Подпроект',
        service: 'subprojects',
        serviceField: 'name',
        value: null,
        urlField: 'Подпроект',
        multiple: true
      },
      {
        id: 3,
        name: 'Подрядчик',
        service: 'mainContractor',
        serviceField: 'name',
        value: null,
        urlField: 'Подрядчик',
        multiple: true
      },
      {
        id: 4,
        name: 'Субподрядчик',
        service: 'mainContractor',
        serviceField: 'name',
        value: null,
        urlField: 'Субподрядчик',
        multiple: true
      },
      {
        id: 5,
        name: 'Субсубподрядчик',
        service: 'mainContractor',
        serviceField: 'name',
        value: null,
        urlField: 'Субсубподрядчик',
        multiple: true
      },
      {
        id: 6,
        name: 'Вид ДТП',
        service: 'trafficaccident_types',
        serviceField: 'name',
        value: null,
        urlField: 'Вид ДТП',
        multiple: true
      }
    ],
    tableFields: [
      {
        id: 'no',
        title: '№ п/п',
        value: 'no',
        type: 'number'
      },
      {
        id: 'not_related_to_prod',
        title: 'Связан с производством',
        value: 'not_related_to_prod',
        type: 'boolean'
      },
      {
        id: 'acc_date',
        title: 'Дата происшествия',
        value: 'acc_date',
        type: 'date',
        dateFormat: 'DD.MM.YYYY'
      },
      {
        id: 'acc_year',
        title: 'Год',
        value: 'acc_year'
      },
      {
        id: 'acc_month',
        title: 'Месяц',
        value: 'acc_month'
      },
      {
        id: 'acc_week',
        title: 'Календарная неделя',
        value: 'acc_week'
      },
      {
        id: 'acc_day_of_week',
        title: 'День недели',
        value: 'acc_day_of_week'
      },
      {
        id: 'acc_time',
        title: 'Время происшествия',
        value: 'acc_time',
        type: 'date',
        dateFormat: 'HH:mm:ss'
      },
      {
        id: 'category_name',
        title: 'Категория происшествия',
        value: 'category_name'
      },
      {
        id: 'subproject',
        title: 'Подпроект',
        value: 'subproject'
      },
      {
        id: 'dtp_place',
        title: 'Место ДТП',
        value: 'dtp_place'
      },
      {
        id: 'accident_site',
        title: 'Участок ДТП',
        value: 'accident_site'
      },
      {
        id: 'contractor',
        title: 'Подрядчик',
        value: 'contractor'
      },
      {
        id: 'subcontractor',
        title: 'Субподрядчик',
        value: 'subcontractor'
      },
      {
        id: 'contractor2',
        title: 'Подрядчик 2',
        value: 'contractor2'
      },
      {
        id: 'subcontractor2',
        title: 'Субподрядчик 2',
        value: 'subcontractor2'
      },
      {
        id: 'type_name',
        title: 'Вид ДТП',
        value: 'type_name'
      },
      {
        id: 'travmatizm',
        title: 'Травмотизм',
        value: 'travmatizm'
      },
      {
        id: 'died',
        title: 'Погибло',
        value: 'died'
      },
      {
        id: 'wounded',
        title: 'Ранено',
        value: 'wounded'
      },
      {
        id: 'micro_injury',
        title: 'Микротравма',
        value: 'micro_injury'
      },
      {
        id: 'ni_inj',
        title: 'Без пострадавших',
        value: 'ni_inj'
      },
      {
        id: 'material_damage',
        title: 'Мат ущерб',
        value: 'material_damage'
      },
      {
        id: 'three_vio_name',
        title: '3 Нарушения',
        value: 'three_vio_name'
      },
      {
        id: 'vehicle_damaged_count',
        title: 'Число поврежденных ТС',
        value: 'vehicle_damaged_count'
      },
      {
        id: 'description',
        title: 'Условия ДТП',
        value: 'description'
      },
      {
        id: 'vehicle_type',
        title: 'Тип ТС',
        value: 'vehicle_type'
      },
      {
        id: 'injured_names',
        title: 'Перечень пострадавших',
        value: 'injured_names'
      },
      {
        id: 'drivers_info',
        title: 'Сведения о водителях',
        value: 'drivers_info'
      },
      {
        id: 'experience',
        title: 'Стаж',
        value: 'experience'
      },
      {
        id: 'age',
        title: 'Возраст',
        value: 'age'
      },
      {
        id: 'normative_doc',
        title: 'Выявленные причины ДТП',
        value: 'normative_doc'
      },
      {
        id: 'corrective_actions',
        title: 'Принятые меры',
        value: 'corrective_actions'
      },
      {
        id: 'plan',
        title: 'План',
        value: 'plan'
      },
      {
        id: 'fact',
        title: 'Факт',
        value: 'fact'
      },
      {
        id: 'subsubcontractor',
        title: 'Субсубподрядчик',
        value: 'subsubcontractor'
      },
      {
        id: 'subsubcontractor2',
        title: 'Субсубподрядчик 2',
        value: 'subsubcontractor2'
      },
      {
        id: 'not_statistic',
        title: 'Подлежит статистическому учету',
        value: 'not_statistic',
        type: 'boolean'
      }
    ]
  },
  violations_report: {
    workspace: [
      {
        id: 1,
        value: 'table'
      }
    ],
    columnsOrder: [
      {
        id: 'Дата происшествия'
      },
      {
        id: 'Год'
      },
      {
        id: 'Месяц'
      },
      {
        id: 'Календарная неделя'
      },
      {
        id: 'Выявил нарушение'
      },
      {
        id: 'Подпроект'
      },
      {
        id: 'Подрядчик'
      },
      {
        id: 'Субподрядчик'
      },
      {
        id: 'Субсубподрядчик'
      },
      {
        id: 'Наршение'
      },
      {
        id: 'Вид нарушения'
      },
      {
        id: 'Классификация нарушения'
      },
      {
        id: 'Принятые меры'
      },
      {
        id: 'Вид ДТП'
      },
      {
        id: 'Тип ТС'
      },
      {
        id: 'ГРЗ'
      },
      {
        id: '3 Нарушения'
      }
    ]
  }
}

export const treeItems = [
  {
    id: 'bdd',
    name: 'БДД',
    children: [
      {
        id: 'violations_report',
        lowerCaseHead: true,
        minWidth: '120',
        value: 'violations_report',
        title: 'Реестр нарушений',
        name: 'Реестр нарушений',
        url: 'violations'
      },
      {
        id: 'bdd_report',
        includeColumns: [
          '№ п/п',
          'Связан с производством',
          'Дата происшествия',
          'Год',
          'Месяц',
          'Календарная неделя',
          'День недели',
          'Время происшествия',
          'Категория происшествия',
          'Подпроект',
          'Место ДТП',
          'Участок ДТП',
          'Подрядчик',
          'Субподрядчик',
          'Подрядчик 2',
          'Субподрядчик 2',
          'Вид ДТП',
          'Травмотизм',
          'Погибло',
          'Ранено',
          'Микротравма',
          'Без пострадавших',
          'Мат ущерб',
          '3 Нарушения',
          'Число поврежденных ТС',
          'Условия ДТП',
          'Тип ТС',
          'Перечень пострадавших',
          'Сведения о водителях',
          'Стаж',
          'Возраст',
          'Выявленные причины ДТП',
          'Принятые меры',
          'План',
          'Факт',
          'Субсубподрядчик',
          'Субсубподрядчик 2',
          'Подлежит статистическому учету'
        ],
        lowerCaseHead: true,
        minWidth: '120',
        value: 'bdd_report',
        title: 'Статистика по ДТП',
        name: 'Статистика по ДТП',
        url: 'dtp_stat'
      },
      {
        id: 'inspections',
        totalField: true,
        minWidth: '120',
        value: 'inspections',
        title: 'Заявки на ТО',
        name: 'Заявки на ТО',
        url: 'inspections',
        urlField: ['main_contractor_id', 'type_id']
      }
    ]
  },
  {
    id: 'monitoring',
    name: 'Мониторинг',
    children: [
      {
        id: 'crossings_geozones',
        minWidth: '120',
        customWidth: {
          Геозона: '200',
          'Время входа в геозону': '200',
          'Время выхода из геозоны': '200',
          Подрядчик: '200',
          Субподрядчик: '200',
          'Длительность пребывания, чч:мм:сс': '200',
          'Средняя скорость в движении, км/ч': '200',
          'Максимальная скорость в движении, км/ч': '200'
        },
        value: 'crossings_geozones',
        title: 'Пересечения геозон',
        name: 'Пересечения геозон',
        url: 'crossing_geozone_report',
        urlField: ['geozone_id']
      },
      {
        id: 'movement_and_parkings',
        minWidth: '120',
        value: 'movement_and_parkings',
        title: 'Движения и стоянки',
        name: 'Движения и стоянки',
        url: 'movement_and_parkings',
        urlField: ['vehicle_id']
      },
      {
        id: 'movements_stat',
        minWidth: '120',
        value: 'movements_stat',
        title: 'Статистика движения ТС',
        name: 'Статистика движения ТС',
        url: 'movement_stat'
      },
      {
        id: 'retranslation_status',
        minWidth: '120',
        value: 'retranslation_status',
        title: 'Фиксация потери сигнала ТС',
        name: 'Фиксация потери сигнала ТС',
        url: 'retranslation_status',
        urlField: ['main_contractor_id']
      },
      {
        id: 'passing_report_hourly_in',
        totalField: true,
        minWidth: '120',
        chartTypes: ['type-1', 'type-5'],
        value: 'passing_report_hourly_in',
        title: 'Суточный почасовой отчёт по въездам ТС',
        name: 'Суточный почасовой отчёт по въездам ТС',
        url: 'passing_report_hourly'
      },
      {
        id: 'passing_report_hourly_out',
        totalField: true,
        minWidth: '120',
        chartTypes: ['type-1', 'type-5'],
        value: 'passing_report_hourly_out',
        title: 'Суточный почасовой отчёт по выездам ТС',
        name: 'Суточный почасовой отчёт по выездам ТС',
        url: 'passing_report_hourly'
      },
      {
        id: 'passing_report_hourly_all',
        totalField: true,
        minWidth: '120',
        chartTypes: ['type-1', 'type-5'],
        value: 'passing_report_hourly_all',
        title: 'Суточный почасовой отчёт по въездам и выездам ТС',
        name: 'Суточный почасовой отчёт по въездам и выездам ТС',
        url: 'passing_report_hourly'
      },
      {
        id: 'passing_report_for_period_in',
        minWidth: '120',
        chartTypes: ['type-6', 'type-4'],
        value: 'passing_report_for_period_in',
        title: 'Отчёт по въездам ТС за период',
        name: 'Отчёт по въездам ТС за период',
        url: 'passing_report_period'
      },
      {
        id: 'passing_report_for_period_out',
        minWidth: '120',
        chartTypes: ['type-6', 'type-4'],
        value: 'passing_report_for_period_out',
        title: 'Отчёт по выездам ТС за период',
        name: 'Отчёт по выездам ТС за период',
        url: 'passing_report_period'
      },
      {
        id: 'passing_report_for_period_all',
        minWidth: '120',
        chartTypes: ['type-6', 'type-4'],
        value: 'passing_report_for_period_all',
        title: 'Отчёт по въездам и выездам ТС за период',
        name: 'Отчёт по въездам и выездам ТС за период',
        url: 'passing_report_period'
      },
      {
        id: 'passing_report_for_period_by_type',
        totalField: true,
        minWidth: '120',
        value: 'passing_report_for_period_by_type',
        chartTypes: ['type-5', 'type-4'],
        title: 'Отчёт по въездам и выездам с разбивкой по типам ТС',
        name: 'Отчёт по въездам и выездам с разбивкой по типам ТС',
        url: 'passing_report_type',
        flyToOptions: { key: 'КПП', query: 'name' }
      },
      {
        id: 'passing_report_load_for_period',
        minWidth: '150',
        value: 'passing_report_load_for_period',
        title: 'Суточный с разбивкой по ТС',
        name: 'Суточный с разбивкой по ТС',
        url: 'passing_report_load',
        flyToOptions: { key: 'Камера', query: 'name' }
      },
      {
        id: 'bsmts_actual_mapping',
        minWidth: '150',
        value: 'bsmts_actual_mapping',
        title: 'Привязка получаемых id bsmts к ТС',
        name: 'Привязка получаемых id bsmts к ТС',
        url: 'bsmts_actual_mapping'
      }
    ]
  },
  {
    id: 'tmc',
    name: 'ТМЦ',
    children: [
      {
        id: 'forecast_arrival',
        minWidth: '120',
        value: 'forecast_arrival',
        title: 'Прогноз прибытия ТС',
        name: 'Прогноз прибытия ТС',
        noOptions: true,
        url: ['vehicles_cargo?arrival_forecast_order=asc']
      },
      // {
      //   id: 'cargo_report_vehicles_report',
      //   minWidth: '120',
      //   doubleTotalField: true,
      //   totalColumn: true,
      //   cellBordered: true,
      //   objectSpanMethod: ({ row, column, rowIndex, columnIndex }) => {
      //     if (columnIndex === 0) {
      //       if (rowIndex % 2 === 0) {
      //         return {
      //           rowspan: 2,
      //           colspan: 1
      //         }
      //       } else {
      //         return {
      //           rowspan: 0,
      //           colspan: 0
      //         }
      //       }
      //     }
      //   },
      //   value: 'cargo_report_vehicles_report',
      //   title: 'Поставки ТМЦ. Авто',
      //   name: 'Поставки ТМЦ. Авто',
      //   url: 'cargo_report'
      // },
      // {
      //   id: 'cargo_report_railway_report',
      //   minWidth: '120',
      //   doubleTotalField: true,
      //   totalColumn: true,
      //   cellBordered: true,
      //   objectSpanMethod: ({ row, column, rowIndex, columnIndex }) => {
      //     if (columnIndex === 0) {
      //       if (rowIndex % 2 === 0) {
      //         return {
      //           rowspan: 2,
      //           colspan: 1
      //         }
      //       } else {
      //         return {
      //           rowspan: 0,
      //           colspan: 0
      //         }
      //       }
      //     }
      //   },
      //   value: 'cargo_report_railway_report',
      //   title: 'Поставки ТМЦ. ЖД',
      //   name: 'Поставки ТМЦ. ЖД',
      //   url: 'cargo_report'
      // },
      {
        id: 'daily_dispatcher',
        minWidth: '120',
        value: 'daily_dispatcher',
        title: 'Ежедневный отчет',
        name: 'Ежедневный отчет',
        url: 'daily_dispatcher'
      },
      {
        id: 'supplies',
        minWidth: '120',
        value: 'supplies',
        title: 'Статистика еженедельная по прибытию грузов на АГХК',
        name: 'Статистика еженедельная по прибытию грузов на АГХК',
        url: 'supplies'
      },
      {
        id: 'supplies_vehicle',
        minWidth: '120',
        value: 'supplies_vehicle',
        title: 'Отчет по поступлению ТС с ТМЦ',
        name: 'Отчет по поступлению ТС с ТМЦ',
        url: 'supplies_vehicle'
      },
      {
        id: 'supplies_cariages',
        minWidth: '120',
        value: 'supplies_cariages',
        title: 'Отчет по поступлению ЖД с ТМЦ ',
        name: 'Отчет по поступлению ЖД с ТМЦ ',
        url: 'supplies_cariages'
      }
    ]
  }
]
