export const related = {
  application: {
    id: 'e01d9bf1-4933-4e13-94ce-66de510916f4',
    data: [],
    config: {
      include: {
        status: {
          only: ['name']
        },
        vehicle: {
          include: {
            contractor: {},
            model: {},
            subproject: {},
            vehicle_type: {}
          }
        }
      },
      where: [
        {
          field: 'status.name',
          op: 'like',
          type: 'OR',
          value: 'нарушений нет'
        },
        {
          field: 'status.name',
          op: 'like',
          type: 'OR',
          value: 'допущен с замечаниями'
        }
      ]
    }
  },
  vehicle: {
    id: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
    data: [],
    config: {}
  },
  permit_type: {
    id: 'e85a3a7e-8ecc-4b54-a147-7e0f451e1807',
    data: [],
    config: {}
  },
  subproject: {
    id: 'fe1656be-32eb-40e4-97f4-835fcd6ded4a',
    data: [],
    config: { only: ['name', 'id'] }
  }
}
