import {
  getDatasourcesByDatatype,
  getModelChildrenIds,
  sortDataByField,
  rDate
} from '@/utils'

// const SIGNS_ID = 'e010924a-220b-4ecf-b423-9e756ff1d18e'
// const SIGN_ICONS_ID = '3c11eb14-f35f-4bc7-b03f-997aaa477959'

export class ModelController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
  }

  async loadModel() {
    try {
      this.parent.loading = true
      const models = await getDatasourcesByDatatype(this.parent, 'model')
      const model = models.find(m => !!m.is_main_model) || models[0]
      const { id, children, name } = model

      const ids = getModelChildrenIds(children)

      // set signs manually
      ids.signs = 'telemetry.signs'
      ids.sign_icons = 'telemetry.sign_icons'

      this.$store.commit('SET_ODD_MODEL', { id, ids, name, children })

      await this.loadBooks(ids)
      await this.loadLists()
    } catch (e) {
      throw new Error(e)
    } finally {
      this.parent.loading = false
    }
  }

  async loadBooks(ids) {
    const { sign_icons, event_classes } = ids
    const signIconsUrl = `objectInfo/${sign_icons}?array=true`
    const eventClassesUrl = `objectInfo/${event_classes}`

    const signIcons = await this.$store.dispatch('GET_REQUEST', {
      url: signIconsUrl
    })
    const eventClasses = await this.$store.dispatch('GET_REQUEST', {
      url: eventClassesUrl
    })

    this.$store.commit('SET_ODD_BOOK', {
      type: 'signIcons',
      book: sortDataByField(signIcons.data, 'name')
    })
    this.$store.commit('SET_ODD_BOOK', {
      type: 'eventClasses',
      book: Object.values(eventClasses.data)
    })
  }

  async loadLists() {
    await this.loadSigns()
    await this.loadEvents()
  }

  async loadSigns() {
    const signsConfig = {
      include: {
        sign_icon: {
          only: ['id', 'resource_id']
        }
      }
    }

    const signsUrl = `objectInfo/telemetry.signs?config=${JSON.stringify(
      signsConfig
    )}`

    const signsData = await this.$store.dispatch('GET_REQUEST', {
      url: signsUrl
    })

    const signs = Object.values(signsData.data)

    // set lists
    this.$store.commit('SET_ODD_LIST', {
      type: 'signs',
      list: signs
    })
  }

  async loadEvents() {
    const { ids } = this.$store.state.odd.model
    const eventsConfig = {
      include: { event_class: { only: ['name'] } }
    }
    const { where } = this.getEventsFilter()

    if (where) {
      eventsConfig.where = where
    }

    const eventsUrl = `objectInfo/${ids.events}?config=${JSON.stringify(
      eventsConfig
    )}`

    const eventsData = await this.$store.dispatch('GET_REQUEST', {
      url: eventsUrl
    })

    const events = Object.values(eventsData.data).sort((a, b) => {
      const c = a.start_time ? rDate.format(a.start_time, 'x') : 0
      const d = b.start_time ? rDate.format(b.start_time, 'x') : 1

      if (c > d) return -1
      if (c < d) return 1
      return 0
    })
    this.$store.commit('SET_ODD_LIST', {
      type: 'events',
      list: events
    })
  }

  getEventsFilter() {
    const config = {}
    const { category, interval } = this.parent.eventsFilter
    const { relatedToProd } = this.parent
    const { eventClasses } = this.$store.state.odd?.books

    if (interval?.active) {
      const { from, to } = interval.prop.interval
      const validFrom = this.parent.$rDate.format(from, 'iso')
      const validTo = this.parent.$rDate.format(to, 'iso')

      if (from || to) {
        if (!config.where) {
          config.where = []
        }

        if (from) {
          config.where.push(
            { field: 'end_time', value: validFrom, op: '>' }
          )
        }

        if (to) {
          config.where.push(
            { field: 'start_time', value: validTo, op: '<' }
          )
        }
      }
    }

    if (category && category !== 'Все') {
      const eventId = eventClasses.find(ev => ev.name === category)?.id
      if (eventId) {
        if (!config.where) {
          config.where = []
        }
        config.where.push(
          {
            field: 'event_class_id',
            value: eventId,
            op: '='
          }
        )
      }
    }

    if (category === 'ДТП') {
      if (!config.where) {
        config.where = []
      }
      config.where.push(
        {
          field: 'trafficaccident.not_related_to_prod',
          value: !relatedToProd || [false, null],
          op: !relatedToProd ? '=' : 'in'
        }
      )
    }

    return config
  }
}
