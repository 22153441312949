export const initialEditorState = {
  isMap: true,
  enabled: false,
  modelId: null,
  mode: 'edit',
  id: '',
  geom_type: '',
  geometry: '',
  history: [],
  historyIndex: 0,
  activeLayers: [],
  modelLayers: [],
  name: '',
  isDrawing: false,
  loading: false,
  // deleting objects helpers
  deletingCallback: null,
  deletingIds: [],
  dependenciesCount: 0,
  // creating object helpers
  withAttributes: false,
  objectFields: [],
  creatingCallback: null,
  creatingObject: null,
  centerGeom: null,
  multiPolygon: false,
  // dependencies map helpers
  dependenciesData: {},
  deletedDependenciesIds: []
}
