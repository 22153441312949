import { jsonToGeojson } from '@/utils'
import { layersConfig } from '../config'
import { getVehiclesRequestConfig } from '../helpers'

export class VehicleController {
  constructor(map) {
    this.map = map
    this.$store = map.$store
    this.mapgl = map.mapgl

    this.data = {}
    this.socketHandler = this.socketHandler.bind(this)
  }

  getFilteredByStatus(vehicles) {
    const { items } = this.$store.state.monitoringTmc.statusFilter
    const activeItems = items.filter(item => item.active)

    items.forEach(item => this.map.$set(item, 'count', 0))

    return vehicles.filter(({ retranslation_status }) => {
      let isVehicleStatusActive = false

      activeItems.forEach(status => {
        if (status.values.includes(retranslation_status)) {
          this.map.$set(status, 'count', status.count + 1)
          isVehicleStatusActive = true
        }
      })

      return isVehicleStatusActive
    })
  }

  async loadVehicles() {
    try {
      this.map.vehiclesLoading = true
      this.data = {}

      const config = getVehiclesRequestConfig(this.$store)
      const uri = `vehicles_cargo?config=${JSON.stringify(config)}`
      const { data: response } = await this.$store.dispatch('GET_REQUEST', { url: uri })

      const data = typeof response === 'object' && response !== null
        ? Object.values(response)
        : response

      let filteredData = data?.filter(data => !!data.geom) || []

      // set vehicle statuses count
      filteredData = this.getFilteredByStatus(filteredData)

      // set to store
      filteredData.forEach(vehicle => {
        this.data[vehicle.track_data_id] = vehicle
      })

      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'noGeomVehicles',
        value: data?.filter(data => !data.geom) || []
      })

      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'vehicles',
        value: filteredData
      })

      this.map.$nextTick(() => (this.map.vehiclesLoading = false))
    } catch (error) {
      throw new Error(error)
    } finally {
      setTimeout(() => {
        this.map.vehiclesLoading = false
      }, 0)
    }
  }

  getLayerData() {
    const { data } = this

    return jsonToGeojson(Object.values(data))
  }

  async addVehicles() {
    await this.loadVehicles()
    const data = this.getLayerData()

    if (this.mapgl.getSource('vehicles')) {
      return
    }

    this.startSocketConnection()

    this.mapgl.addSource('vehicles', {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 36,
      data
    })

    // add clusters
    this.mapgl.addLayer({
      id: 'vehicles-clusters',
      source: 'vehicles',
      ...layersConfig.vehicles_clusters
    })
    this.mapgl.addLayer({
      id: 'vehicles-cluster-count',
      source: 'vehicles',
      ...layersConfig.vehicles_clusters_count
    })

    this.mapgl.addLayer({
      id: 'vehicles',
      source: 'vehicles',
      ...layersConfig.vehicles
    })

    this.addHandlers()
  }

  removeVehicles() {
    if (this.mapgl.getLayer('vehicles')) {
      this.mapgl.removeLayer('vehicles')
    }
    if (this.mapgl.getLayer('vehicles-clusters')) {
      this.mapgl.removeLayer('vehicles-clusters')
    }
    if (this.mapgl.getLayer('vehicles-cluster-count')) {
      this.mapgl.removeLayer('vehicles-cluster-count')
    }
    if (this.mapgl.getSource('vehicles')) {
      this.mapgl.removeSource('vehicles')
    }

    // this.stopSocketConnection()
  }

  async updateVehicles() {
    const source = this.mapgl.getSource('vehicles')

    if (source) {
      await this.loadVehicles()
      const data = this.getLayerData()

      source.setData(data)
    }
  }

  socketHandler(data) {
    for (let i = 0; i < data.length; i++) {
      const track = data[i]
      const splits = track.split(';')

      const uid = parseInt(splits[0])
      const lat = parseFloat(splits[1])
      const lon = parseFloat(splits[2])
      const course = parseFloat(splits[3])

      const current = this.data[uid]

      if (current) {
        current.geom.coordinates[0] = lon
        current.geom.coordinates[1] = lat
        current.course = course

        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'lastUpdatedVehicleId',
          value: current.id
        })
      }
    }

    const layerData = this.getLayerData()
    const source = this.mapgl.getSource('vehicles')

    if (source) {
      source.setData(layerData)
    }
  }

  startSocketConnection() {
    this.map.$socket.on('track_data', this.socketHandler)
  }

  stopSocketConnection() {
    this.map.$socket.off('track_data', this.socketHandler)
  }

  addHandlers() {
    this.mapgl.on('mousemove', 'vehicles', e => {
      this.mapgl.getCanvas().style.cursor = 'pointer'
    })

    this.mapgl.on('mouseleave', 'vehicles', e => {
      this.mapgl.getCanvas().style.cursor = ''
    })

    this.mapgl.on('click', 'vehicles', e => {
      const { id, track_data_id } = e.features[0].properties

      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardId',
        value: id
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'trackDataId',
        value: track_data_id
      })
    })

    this.mapgl.on('mousemove', 'vehicles-clusters', e => {
      this.mapgl.getCanvas().style.cursor = 'pointer'
      if (!e.features[0]) {
        this.map.popupListSettings.display = 'none'
        this.map.popupListSettings.values = []
      }
    })

    this.mapgl.on('mouseleave', 'vehicles-clusters', () => {
      this.mapgl.getCanvas().style.cursor = ''
    })

    this.mapgl.on('click', 'vehicles-clusters', e => {
      if (!e.features[0]) return
      const { x, y } = e.point
      const clusterId = e.features[0].properties.cluster_id
      const point_count = e.features[0].properties.point_count
      const clusterSource = this.mapgl.getSource('vehicles')

      clusterSource.getClusterLeaves(
        clusterId,
        point_count,
        0,
        (_, features) => {
          this.map.popupListSettings.top = y - 40
          this.map.popupListSettings.left = x - 50
          this.map.popupListSettings.display = 'block'
          this.map.popupListSettings.values = features
        }
      )
    })

    this.mapgl.on('mousemove', e => {
      if (!this.mapgl.getLayer('vehicles-clusters')) return
      const { x, y } = e.point
      const bbox = [
        [x - 5, y - 5],
        [x + 5, y + 5]
      ]
      const features = this.mapgl.queryRenderedFeatures(bbox, {
        layers: ['vehicles-clusters']
      })

      if (!features.length) {
        this.map.popupListSettings.display = 'none'
        this.map.popupListSettings.values = []
      }
    })
  }

  toggleActiveStyling(id) {
    if (!this.mapgl.getLayer('vehicles')) return
    const iconDefaultValue = layersConfig.vehicles.layout['icon-image']

    if (id) {
      const iconValue = [
        'match',
        ['get', 'id'],
        id,
        [
          'case',
          ['==', ['get', 'type_name'], 'ЖД'], 'aghk-train-selected',
          ['==', ['get', 'type_name'], 'Автобус'], 'aghk-bus-selected',
          'aghk-car-selected'
        ],
        iconDefaultValue
      ]

      this.mapgl.setLayoutProperty('vehicles', 'icon-image', iconValue)
    } else {
      this.mapgl.setLayoutProperty('vehicles', 'icon-image', iconDefaultValue)
    }
  }
}
