<template>
  <div
    v-loading="iconsLoading"
    class="r-info-layers"
  >
    <component
      :is="`${layer}-button`"
      v-for="layer in filteredLayers"
      :ref="layer"
      :key="layer"
      :mapgl="mapgl"
      :module="module"
      :vehicle-category-id="vehicleCategoryId"
    />
  </div>
</template>

<script>
import TilelayersButton from './r-info-layers/layers/tilelayers'
import VehiclesButton from './r-info-layers/layers/vehicles'
import GeozonesButton from './r-info-layers/layers/geozones'
import SignsButton from './r-info-layers/layers/signs'
import EventsButton from './r-info-layers/layers/events'
import GraphButton from './r-info-layers/layers/graph'
import TransportSituationButton from './r-info-layers/layers/transport-situation'
import PassingStatisticButton from './r-info-layers/layers/passing-statistic/passing-statistic'
import KppAghkButton from './r-info-layers/layers/kpp-aghk'
import KppAgpzButton from './r-info-layers/layers/kpp-agpz'
import AbkAgpzButton from './r-info-layers/layers/abk-agpz'
import OverpassesAgpzButton from './r-info-layers/layers/overpasses-agpz'
import ShahmatkaButton from './r-info-layers/layers/shahmatka'
import WerehouseButton from './r-info-layers/layers/warehouse'

import { mapGetters } from 'vuex'

export default {
  components: {
    VehiclesButton,
    TilelayersButton,
    GeozonesButton,
    SignsButton,
    GraphButton,
    TransportSituationButton,
    EventsButton,
    PassingStatisticButton,
    KppAghkButton,
    KppAgpzButton,
    AbkAgpzButton,
    OverpassesAgpzButton,
    ShahmatkaButton,
    WerehouseButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    enabledLayers: {
      type: Array,
      default: () => [
        'vehicles',
        'graph',
        'transportSituation',
        'geozones',
        'signs',
        'events',
        'passingStatistic',
        'kppAghk',
        'kppAgpz',
        'abkAgpz',
        'overpassesAgpz',
        'shahmatka',
        'werehouse',
        'tilelayers'
      ]
    },
    lockedLayers: {
      type: Array,
      default: () => []
    },
    module: {
      type: String,
      default: 'default'
    },
    vehicleCategoryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      layers: [
        'vehicles',
        'graph',
        'transportSituation',
        'geozones',
        'signs',
        'events',
        'passingStatistic',
        'kppAghk',
        'kppAgpz',
        'abkAgpz',
        'overpassesAgpz',
        'shahmatka',
        'werehouse',
        'tilelayers'
      ],
      eventsSourceId: null
    }
  },
  computed: {
    ...mapGetters([
      'isLayerIdActive',
      'layersToRenderList',
      'activeLayersList',
      'lockedLayersList'
    ]),
    filteredLayers() {
      return this.layers.filter(l => this.enabledLayers.includes(l))
    },
    iconsLoading() {
      return this.$store.state.odd.iconsLoading
    }
  },
  watch: {
    '$store.state.theme'() {
      this.loadIcons()
    }
  },
  mounted() {
    this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
      field: 'lockedLayers',
      module: this.module,
      value: this.lockedLayers.map(l => this.$refs[l][0].id)
    })
  },
  created() {
    this.loadIcons()
  },
  methods: {
    async loadIcons() {
      try {
        if (this.enabledLayers.includes('vehicles')) {
          await this.loadVehicleIcons()
        }

        if (this.enabledLayers.includes('events')) {
          await this.loadEventsIcons()
        }

        await this.loadSdfIcons()
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadEventsIcons() {
      const iconsList = ['work-icon', 'accident-icon', 'event-icon', 'sign-icon']
      const icons = iconsList.map(e => ({
        name: e,
        imageUrl: require(`@/assets/images/odd/${e}.svg`)
      }))
      const MAP_ICON_SIZE = 44

      return Promise.all(
        icons.map(
          ({ name, imageUrl }) =>
            new Promise(resolve => {
              const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
              image.crossOrigin = 'Anonymous'
              image.style.backgroundPosition = '50%, 50%'
              image.style.backgroundSize = '100%'
              image.addEventListener('load', () => {
                if (!this.mapgl.hasImage(name)) {
                  this.mapgl.addImage(name, image)
                }
                resolve()
              })
              image.src = imageUrl
            })
        )
      ).then(() => {})
    },
    async loadSdfIcons() {
      const iconsList = ['folder-layer']
      const icons = iconsList.map(e => ({
        name: e,
        imageUrl: require(`@/assets/icons/mono/layer/${e}.svg`)
      }))
      const general = ['storehouse', 'kpp-gate']
      general.forEach(e => {
        icons.push({
          name: e,
          imageUrl: require(`@/assets/icons/mono/general/${e}.svg`)
        })
      })
      const MAP_ICON_SIZE = 44

      return Promise.all(
        icons.map(
          ({ name, imageUrl }) =>
            new Promise(resolve => {
              const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
              image.crossOrigin = 'Anonymous'
              image.style.backgroundPosition = '50%, 50%'
              image.style.backgroundSize = '100%'
              image.addEventListener('load', () => {
                if (!this.mapgl.hasImage(name)) {
                  this.mapgl.addImage(name, image, { sdf: true })
                }
                resolve()
              })
              image.src = imageUrl
            })
        )
      ).then(() => {})
    },
    loadVehicleIcons() {
      const iconsBank = [
        'aghk-bus-default',
        'aghk-bus-disabled',
        'agpz-bus-default',
        'agpz-bus-disabled'
      ]
      const icons = iconsBank.map(e => ({
        name: e,
        imageUrl: require(`@/assets/images/monitoring/${e}.svg`)
      }))
      const MAP_ICON_SIZE = 44

      return Promise.all(
        icons.map(
          ({ name, imageUrl }) =>
            new Promise(resolve => {
              const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
              image.crossOrigin = 'Anonymous'
              image.style.backgroundPosition = '50%, 50%'
              image.style.backgroundSize = '100%'
              image.addEventListener('load', () => {
                if (!this.mapgl.hasImage(name)) {
                  this.mapgl.addImage(name, image)
                }
                resolve()
              })
              image.src = imageUrl
            })
        )
      ).then(() => {})
    },
    async rerenderComponent() {
      await this.loadIcons()
      const layers = this.layersToRenderList(this.module)

      Object.values(this.$refs).forEach(([component]) => {
        if (component.id === 'tile_layers') {
          component.updateLayers(layers)
        } else if (layers.includes(component.id)) {
          component.addHandler()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.r-info-layers {
  background-color: var(--modal_bg) !important;
  padding: 4px;
  border-radius: var(--border-radius);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
}
</style>
